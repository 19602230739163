<template>
    <div>
        <div class="btn" v-for="nav,i in navs" :key="i">
            <RouterLink :to="nav.link">
                <PButton :active="nav.link==curPath" size="middle">
                  <i v-if="nav.icon" :class="'iconfont '+nav.icon" >&nbsp;</i>
                  {{nav.name}}
                </PButton>
            </RouterLink>
        </div>
    </div>
</template>

<script>
import {useRoute} from 'vue-router';
import {ref, watch} from 'vue'
import pButton from '../../../components/library/pButton.vue';
export default {
  components: { pButton },
  name: 'Nav',
  props:{
    navs:{
      type:Array,
      default:()=>[]
    }
  },
  setup(){
    

    const route=useRoute();
    const curPath=ref('');
    //curPath.value=route.path;
    watch(()=>route.path,(newVal)=>{
      curPath.value=newVal;
    },{immediate:true})
    //console.log("path",curPath.value)
    
    return {curPath}
  }
}
</script>

<style scoped lang='less'>
.btn{
    margin-bottom:30px;
}
</style>