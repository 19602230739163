<template>
<div class="index">
    <div class="nav"><Nav class="nc" :navs="nav"></Nav></div>
    <div class="content"><router-view></router-view></div>
</div>
</template>

<script>
import Nav from './components/nav.vue'
import {useStore} from 'vuex'
import {ref} from 'vue'
export default {
    name: 'Personal',
    components:{Nav},
    setup(){
        const store=useStore();
        const nav=ref([]);
        nav.value=store.state.navs.pNav;
        return {nav};
    }
}
</script>

<style scoped lang="less">
.index{
    //border: solid;
    width: 1260px;
    margin: auto;
    display: flex;
    //height: 500px;
    .nav{
        //width: 300px;
        //border: solid;
        //float: left;
        margin: 10px;
        padding: 20px;
        height: 400px;
        background-color: white;
        box-shadow: 0 3px 8px rgba(0,0,0,0.2);
        .nc{
            margin-top: 20px;
        }
    }
    .content{
        width: 1000px;
        //float: left;
        //border: solid;
        //height: 300px;
        margin-top: 10px;
        background-color: white;
        box-shadow: 0 3px 8px rgba(0,0,0,0.2);
        padding: 20px;
    }
}
</style>